@import url('https://fonts.googleapis.com/css?family=Karla|Vidaloka&display=swap');

.bg-piiia-light-grey {
  background-color: #f3f3f3;
}

.bg-piiia-grey {
  background-color: #565455;
}

.bg-piiia-dark-grey {
  background-color: #D6D6D6;
}

.piiia-grey {
  color: #565455 !important;
}

.piiia-light-grey {
  color: #f3f3f3;
}

.piiia-dark-grey {
  color: #D6D6D6;
}

.piiia-rust {
  color: #b0583a;
}

.piiia-red {
  color: #721834 !important;
}


.hover-piiia-blank:hover,
.hover-piiia-blank:focus {
  color: #565455;
}

.hover-piiia-blank-white:visited,
.hover-piiia-blank-white:link,
.hover-piiia-blank-white:hover,
.hover-piiia-blank-white:focus {
  color: white;
}

.hover-piiia-blank-black:visited,
.hover-piiia-blank-black:link,
.hover-piiia-blank-black:hover,
.hover-piiia-blank-black:focus {
  color: black;
}

/* unvisited link */
a.pepelink:link {
  color: #565455;
}

/* visited link */
a.pepelink:visited {
  color: #565455;
}

/* mouse over link */
a.pepelink:hover {
  color: #565455;
}

/* selected link */
a.pepelink:active {
  color: #565455;
}



.hover-piiia-brown:hover,
.hover-piiia-brown:focus {
  color: #AB9992 !important;
}

.hover-piiia-turqoise:hover,
.hover-piiia-turqoise:focus {
  color: #b0583a !important;
}


/* unvisited link */
/*a:link {
  color: #721834;
}*/

/* visited link */
/*a:visited {
  color: #565455;
}*/

/* mouse over link */
/*a:hover {
  color: #AB9992;
}*/

/* selected link */
/*a:active {
  color: #a8d2cf;
}*/

.piiia-highlighter {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='1px' height='1px' viewBox='0 0 1 1' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='1' height='1' fill='white' /%3E%3C/svg%3E")
    no-repeat 100% 100%;
  background-size: 100% 50%;
}

.piiia-highlighter-grey {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='1px' height='1px' viewBox='0 0 1 1' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='1' height='1' fill='lightgrey' /%3E%3C/svg%3E")
    no-repeat 100% 100%;
  background-size: 100% 15%;
}

.vidaloka {
  font-family: "Vidaloka", serif;
}

.mt4 {
  margin-top: 3rem !important;
}

.karla {
  font-family: "Karla", sans-serif;
}

.dm-serif {
  font-family: 'DM Serif Display', serif;
}

.roboto-slab {
  font-family: 'Roboto Slab', serif;
}

.tracked-light {
  letter-spacing: 0.02em;
}

button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  /*border is optional*/
  cursor: pointer;
}

a {
  text-decoration: none;
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.mt-auto { margin-top: auto; }
.mb-auto { margin-top: bottom; }

/* override Vant.css */
.van-nav-bar {
  background-color: #000000 !important; 
}
.van-nav-bar__title {
  color: white !important;
  font-family: "DM Serif Display", serif;
  cursor: pointer;
}

.van-icon {
  color:black !important;
}

.van-nav-bar .van-icon {
  color: white !important;
}

.van-loading__spinner {
  color: black !important;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}


.btn-link {
  background: none!important;
  border: none;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.ben {
  pointer-events:none
}

ul { 
  display: block !important;
  list-style: disc inside none !important;
}
li { 
  display: list-item !important;
}
ul ul, ol ul {
  list-style-type: circle !important;
  margin-left: 15px !important;
}
ol ol, ul ol { 
  list-style-type: lower-latin !important; 
  margin-left: 15px !important;
}